import { configureStore } from '@reduxjs/toolkit';
import operatingHoursReducer from "./operatingHoursSlice";
import servicesReducer from './serviceSlice';

const store = configureStore({
  reducer: {
    operatingHours: operatingHoursReducer ,
    services: servicesReducer, 
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
