import React, { useEffect, useState } from "react";
import styles from '../../styles/towelService/selectItem.module.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import subIcon from '../../assets/icons/sub.svg';
import {ReactComponent as ReactplusIcon} from '../../assets/icons/plus.svg'
import {ReactComponent as ReactColoredsubIcon} from '../../assets/icons/coloredSub.svg'


interface SelectItemProps {
    onContinue: (Data: {id: number; count: number;}) => void;
 }

 interface Towel {
    id: number;
    deliveryFee: number;
    price: string;
    stock: number;
    deliveryEstimate: string;
    size: string;
  }

const SelectItem: React.FC<SelectItemProps> = ({ onContinue }) => {
    const navigate = useNavigate()
    const [count, setCount] = useState<number>(1);
    const [currency, setCurrency] = useState<any>(null);
    const [towels, setTowels] = useState<Towel[]>([])
    const increment = () => setCount(count + 1);
    const decrement = () => count > 0 && setCount(count - 1);
  
    useEffect(() => {
        const fetchServices = async () => {
          try {
            const token = localStorage.getItem('PropertyToken');
            if (!token) {
              throw new Error('PropertyToken not found');
            }
    
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}services/freshTowels`, {
              headers: {
                propertyToken: token
              }
            });
            
            setTowels(response.data.towelServices)
            setCurrency(response.data.currency)
          } catch (error) {
            console.error('There was an error fetching the towels!', error);
          }
        };
    
        fetchServices();
    
      }, []);

      const handleSubmit = ()=>{
        onContinue({id: towels[0].id, count})
      }
  return (
    <div className={styles.towelSelectItems}>
    <div className={styles.header}>
        <div className={styles.backArrow} onClick={()=> {navigate('/app/')}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>

        </div>
        <div className={styles.image}>
        </div>
    </div>
    <div className={`${styles.article}`}>
        <h2>Bath towel</h2>
        <p>Single white bath towel, {towels[0]?.size}</p>
    </div>
    {towels.length > 0 && currency &&
      <div className={`flex ${styles.footer}`}>
              <div className={styles.counter}>

                  <button
                      className={styles.button}
                      onClick={decrement}
                      disabled={count === 1}
                      >
                    {count > 1 ?(<ReactColoredsubIcon/>) : (<img src={subIcon} alt="" />)}

                  </button>
                  <span className={styles.count}>{count}</span>
                  <button disabled={count === 50} className={styles.button} onClick={increment}>
                      <ReactplusIcon/>
                  </button>
                </div>
              <button onClick={handleSubmit} className={`flex justify-between ${styles.continue}`}>
                      <p>Add</p>
                      <p>{currency.currency} {(count * (parseFloat(towels[0]?.price))).toFixed(2)}</p>                
              </button>
      </div>
    }
    </div>
  );
};


export default SelectItem;
