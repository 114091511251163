import React, { useEffect, useState } from "react";
import styles from "../../styles/orderDetail.module.css";
import { Order } from "../../utils/types/services";
import { paymentMethods } from "../../utils/paymentMethods";
import {
  convertDateBackOnly,
} from "../../utils/times/timeDateConvert";
import { Link, useNavigate } from "react-router-dom";

interface PaymentProps {
  order: Order;
}

const LaundryOrderHistory: React.FC<PaymentProps> = ({ order }) => {
  const [groupedData, setGroupedData] = useState<any>(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (order.laundryOrderItems) setGroupedData(groupItemsBySection(order.laundryOrderItems));
    
  }, [order]);
  

  const groupItemsBySection = (items: any) => {
    return items.reduce((acc: any, item: any) => {
      const sectionName = item.laundryItem.laundrySection.name;
      const sectionImage = item.laundryItem.laundrySection.image;
      if (!acc[sectionName]) {
        acc[sectionName] = {
          image: sectionImage,
          items: [],
          totalAmount: 0,
        };
      }
      acc[sectionName].items.push(item);
      acc[sectionName].totalAmount += parseFloat(item.laundryItem.price) * item.quantity;
      return acc;
    }, {});
  };

  const toCamelCase = (str: string): string  => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }

  return (
    <>
      <div className={styles.container}>
      <div className={styles.header}>
          <div className={`flex justify-between ${styles.upper}`}>
                <div
                  className={styles.backArrow}
                  onClick={() => {
                    navigate("/app/orders");
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
                  </svg>
                </div>
                <Link to="https://wa.me/971586655040" target="_blank">
                  <button
                    className="flex items-center justify-between"
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0352 3.33147C11.7476 2.04359 10.0351 1.334 8.21073 1.33325C4.45151 1.33325 1.39202 4.38966 1.39052 8.14622C1.39 9.34709 1.704 10.5193 2.30086 11.5526L1.33331 15.0832L4.94882 14.1357C5.94503 14.6787 7.0666 14.9647 8.20796 14.9651H8.21084C11.9697 14.9651 15.0295 11.9084 15.0309 8.15168C15.0317 6.33105 14.3229 4.61929 13.0352 3.33147ZM8.21073 13.8144H8.20837C7.19125 13.8139 6.19368 13.5409 5.32321 13.025L5.11632 12.9022L2.97082 13.4645L3.54346 11.3747L3.40862 11.1605C2.84118 10.2588 2.54153 9.21666 2.54204 8.14663C2.54323 5.02427 5.08624 2.484 8.21301 2.484C9.72712 2.48449 11.1504 3.0743 12.2206 4.14474C13.2909 5.21518 13.8799 6.63802 13.8794 8.15125C13.8781 11.2739 11.3352 13.8144 8.21073 13.8144ZM11.3201 9.57298C11.1497 9.48773 10.3119 9.076 10.1556 9.01911C9.99953 8.96229 9.88579 8.93399 9.7723 9.10436C9.65865 9.27473 9.33211 9.65825 9.23265 9.77179C9.13318 9.88545 9.03389 9.89969 8.86344 9.81444C8.69301 9.72925 8.14394 9.54941 7.49299 8.96942C6.98643 8.51799 6.64445 7.96049 6.54499 7.79014C6.4457 7.6196 6.54413 7.53635 6.61974 7.44267C6.80422 7.21382 6.98895 6.97389 7.04574 6.86034C7.1026 6.74669 7.07413 6.64726 7.03146 6.56207C6.98895 6.47689 6.64815 5.63891 6.50617 5.29793C6.36774 4.96611 6.22736 5.0109 6.12269 5.00572C6.0234 5.00076 5.90974 4.99975 5.79609 4.99975C5.6825 4.99975 5.49785 5.04229 5.34161 5.21283C5.18544 5.38329 4.74527 5.7951 4.74527 6.63308C4.74527 7.47106 5.35589 8.28056 5.44108 8.3942C5.52624 8.50785 6.64278 10.2274 8.35219 10.9648C8.75878 11.1403 9.07615 11.245 9.32372 11.3235C9.73198 11.453 10.1034 11.4347 10.3971 11.3909C10.7245 11.342 11.4052 10.979 11.5473 10.5814C11.6893 10.1837 11.6893 9.84289 11.6466 9.77179C11.6041 9.70081 11.4905 9.65825 11.3201 9.57298Z" fill="#323E48"/>
                    </svg>
                    Help
                  </button>
                </Link>
            </div>
            <div className={`flex justify-between ${styles.serviceHeader}`}>
              <h1>{order.service.name}</h1>
              <span>Order #{order.id}</span>
            </div>
        </div>

        <div className={styles.checkOut}>
          <div className={styles.details}>
            <div className={`flex justify-between ${styles.subHeader}`}>
              <h4>Your order</h4>
              <span className={styles[order ? order.status.toLowerCase() : ""]}>
                {order.status}
              </span>
            </div>
            {groupedData &&
                Object.keys(groupedData).map((sectionName) => (
                  <div key={sectionName} className={` flex ${styles.section}`}>
                    <img
                      src={groupedData[sectionName].image}
                      alt={sectionName}
                    />
                    <div>
                      <h2>{sectionName}</h2>
                      {groupedData[sectionName].items.map((item: any) => (
                        <div key={item.id} className={styles.item}>
                            <p>
                              x{item.quantity} {item.laundryItem.name}:{" "}
                              {sectionName !== "Dry Cleaning" &&
                                item.laundryItem.description}
                            </p>
                        </div>
                      ))}
                      <p>
                      {order.currency.currency} {groupedData[sectionName].totalAmount.toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))
              }
          </div>
          <div className={styles.details}>
            <div className={`flex justify-between ${styles.subHeader}`}>
              <h4>Details</h4>
            </div>
            <div className={`${styles.serviceDetail}`}>
              <div className={`flex ${styles.detailsItem}`}>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.25 19.85C12.25 19.85 5.95001 14.675 5.95001 9.94999C5.95001 6.47059 8.77061 3.64999 12.25 3.64999C15.7294 3.64999 18.55 6.47059 18.55 9.94999C18.55 14.675 12.25 19.85 12.25 19.85Z"
                      stroke="#8B98A4"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2501 12.4251V12.4251C10.8831 12.4251 9.77509 11.3171 9.77509 9.9501V9.9501C9.77509 8.58307 10.8831 7.4751 12.2501 7.4751V7.4751C13.6171 7.4751 14.7251 8.58307 14.7251 9.9501V9.9501C14.7251 11.3171 13.6171 12.4251 12.2501 12.4251Z"
                      stroke="#8B98A4"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <p>
                  {order.property.departmentNumber},{" "}
                  {order.property.buildingName}, {order.property.Area.name.toLowerCase()}
                </p>
              </div>
              <div className={`flex ${styles.detailsItem}`}>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.87988 3.74512V6.49512"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.1299 3.74512V6.49512"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.75 9.70512H14.17"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.1299 9.9248V20.1348"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.1299 9.86511L22.3099 13.0451"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.13 9.86511L15.95 13.0451"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12512C2.75 6.46512 4.09 5.12512 5.75 5.12512H16.26C17.28 5.12512 18.18 5.63512 18.72 6.41512"
                      stroke="#8B98A4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div className={styles.times}>
                  <p>Pick up time</p>
                  <p>
                    {convertDateBackOnly(order.pickupDate)}, between {order.pickupTime}
                  </p>
                </div>
              </div>
              <div className={`flex ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.87988 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.1299 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75 9.70512H14.17"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 20.0752V9.86523"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 20.1348L22.3099 16.9548"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1302 20.1348L15.9502 16.9548"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12511C2.75 6.46511 4.09 5.12511 5.75 5.12511H16.26C17.28 5.12511 18.18 5.63511 18.72 6.41511"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className={styles.times}>
                <p>Dropoff time</p>
                <p>
                  {convertDateBackOnly(order.dropoffDate)}, {order.dropoffTime}
                </p>
              </div>
              </div>
            </div>
          </div>
          <div className={styles.paymentSummary}>
            <h4>Payment summary</h4>
            <div className={` ${styles.serviceDetail}`}>
              <div className={styles.amounts}>
                <div
                  className={` flex justify-between ${styles.paymentMethod}`}
                >
                  <p>Payment method</p>
                  <p>
                    {
                      paymentMethods.find(
                        (method) => method.value === order.payment.paymentMethod
                      )?.label
                    }
                  </p>
                </div>
                <hr />
                <div className={`${styles.subTotal}`}>
                  {order &&
                    order.laundryOrderItems.map((item: any) => (
                      <div
                        key={item.id}
                        className={` flex justify-between ${styles.subTotal}`}
                      >
                        <p>
                          x{item.quantity} {item.laundryItem.name}:{" "}
                          {item.laundryItem.laundrySection.name !==
                            "Dry Cleaning" && item.laundryItem.description}
                        </p>
                        <p>{order.currency.currency} {item.laundryItem.price}</p>
                      </div>
                    ))}
                </div>
                <hr />
                <div className={` flex justify-between ${styles.subTotal}`}>
                  <p>Subtotal</p>
                  <p>{order.currency.currency} {order.payment.subtotal}</p>
                </div>
                <div className={` flex justify-between ${styles.deliveryFee}`}>
                  <p>Service fee</p>
                  <p>{order.currency.currency} {order.serviceFee}</p>
                </div>
                <hr />
                <div className={` flex justify-between ${styles.total}`}>
                  <p>Total amount</p>
                  <p>{order.currency.currency} {order.payment.totalAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaundryOrderHistory;
