import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import store from './redux/store';
import Main from './pages/main';
import HandlePropertyToken from './utils/handlePropertyToken'
import PropertyNotFound from './pages/PropertyNotFound';
const App: React.FC = () => {
  
  return (
    <Provider store={store}>
      <Router>
        <Routes>
        <Route
            path="/app/*"
            element={<HandlePropertyToken element={<Main />} />}
          />
          <Route path="*" element={<PropertyNotFound/>} />
        </Routes>
      </Router>  
    </Provider>
  );
};

export default App;