import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/dashboard.module.css";
import axios from "axios";
import { Service } from "../utils/types/services";
import NavBar from "../components/navbar/NavBar";
import NewOrders from "../components/newOrders";
import { imgTwoTexts } from "../utils/skeleton";
import ImageWithLoader from "../components/imageWithLoader";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { fetchServices } from "../redux/serviceSlice";
import { fetchOperatingHours } from "../redux/operatingHoursSlice";
import { format, parse } from "date-fns";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [topPosition, setTopPosition] = useState(-15);
  const [themeColor, setThemeColor] = useState("#ffffff");

  const { services, loading, error } = useSelector(
    (state: RootState) => state.services
  );
  const { operatingHours } = useSelector(
    (state: RootState) => state.operatingHours
  );

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--primary-app-header-color");
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchOperatingHours(4));
  }, [dispatch]);

  const navigation = (service: Service) => {
    if (!service.isAvailable) return; // Prevent navigation if service is unavailable

    switch (service.service?.code || service.code) {
      case 1:
        navigate("/app/housekeeping/checkout");
        break;
      case 2:
        navigate("/app/laundry/checkout");
        break;
      case 3:
        navigate("/app/food/checkout");
        break;
      case 4:
        navigate("/app/freshTowels/checkout");
        break;
      case 5:
        navigate("/app/groceries/categories");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#4D80A4" />
      </Helmet>
      <NavBar />
      <div className={styles.header}>
        <h2>
          What can
          <br />
          I do for
          <br />
          <span>you?</span>
        </h2>
      </div>
      <div className={styles.services}>
        <div style={{ top: `${topPosition}px` }} className={styles.container}>
          {loading ? (
            <>
              {[1, 2, 3, 4].map((_, index) => (
                <div key={index}>{imgTwoTexts()}</div>
              ))}
            </>
          ) : error ? (
            <div className={styles.error}>
              <p>Error: {error}</p>
            </div>
          ) : (
            <>
              {services.map((service) => {
                const isServiceAvailable =
                  service.isAvailable &&
                  !(
                    service?.service?.code === 4 &&
                    operatingHours &&
                    !operatingHours.availability.available
                  );

                return (
                  <div
                    key={service.id}
                    className={`${styles.sectionItem} flex flex-row flex-wrap items-center mt-2`}
                    onClick={() => isServiceAvailable && navigation(service)}
                    style={{
                      cursor: isServiceAvailable ? "pointer" : "not-allowed",
                    }} // Change cursor based on availability
                  >
                    <div
                      className={`${styles.imageContainer} ${
                        !isServiceAvailable ? styles.unavailableImage : ""
                      }`}
                    >
                      <ImageWithLoader
                        src={service.service?.image || service.image}
                        alt={service.service?.name || service.name}
                      />
                    </div>
                    <article className="ml-2 flex-1">
                      <div className="flex flex-row flex-wrap items-center">
                        <h2
                          className={
                            !isServiceAvailable ? styles.disabledText : ""
                          }
                        >
                          {service.service?.name || service.name}
                        </h2>
                        {service.price && (
                          <p
                            className={`${styles.price} ${
                              !isServiceAvailable ? styles.disabledText : ""
                            }`}
                          >
                            {service.currency.currency} {service.price}
                          </p>
                        )}
                      </div>
                      {service.isAvailable ? (
                        <>
                          {service?.service?.code === 4 &&
                          operatingHours &&
                          !operatingHours.availability.available ? (
                            <p className={styles.descriptionUnavailable}>
                              Delivery close - Opens{" "}
                              {operatingHours.availability.nextAvailable.day} -{" "}
                              {
                                format(parse(operatingHours.availability.nextAvailable.fromTime, "HH:mm:ss", new Date()), "h:mm a")
                              }
                            </p>
                          ) : (
                            <p>{service.description}</p>
                          )}
                        </>
                      ) : (
                        <>
                          <p className={styles.descriptionUnavailable}>
                            Service temporarily unavailable
                          </p>
                        </>
                      )}
                    </article>
                  </div>
                );
              })}
              <div className={styles.newOrders} ref={containerRef}>
                <NewOrders />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
