import React, { useEffect, useState } from "react";
import styles from "../../styles/laundryService/selectTime.module.css";
import PickupTimes from "./pickup";
import DropoffTimes from "./dropoff";
import { threeRowTexts } from "../../utils/skeleton";
import { fetchOperatingHours } from "../../redux/operatingHoursSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  applyPickupMinsToToday,
  generateLaundryDroppoffSlots,
  generateLaundrySlots,
  getNext7DaysWithDates,
  getNextDropOff7DaysWithDates,
} from "../../utils/times/operatingHours";
import { TimeZoneInfo } from "../../utils/types/times";
import { toZonedTime } from "date-fns-tz";
import { addMinutes, format, isSameDay, parse } from "date-fns";


interface SelectTimeProps {
  onContinue: (
    pickupData: { pickupDate: string; pickupTime: string },
    dropoffData: { dropOffDate: string; dropOffTime: string }
  ) => void;
  onBack: () => void;
}

const LaundrySelectTime: React.FC<SelectTimeProps> = ({
  onBack,
  onContinue,
}) => {
  const serviceCode: number = 2;
  const [isPickupTime, setIsPickupTime] = useState<boolean>(false);
  const [isDropOffTime, setIsDropOffTime] = useState<boolean>(false);
  const [pickupDate, setPickupDate] = useState<string>("");
  const [pickupTime, setPickupTime] = useState<string>("");
  const [dropOffTime, setDropOffTime] = useState<string | null>(null);
  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dropOffmodalIsOpen, setDropOffmodalIsOpen] = useState(false);
  const [pickupmodalIsOpen, setPickupmodalIsOpen] = useState(false);

  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [pickupshouldAnimateOut, setPickupShouldAnimateOut] = useState(false);
  const { operatingHours, error } = useSelector(
    (state: RootState) => state.operatingHours
  );
  const dispatch = useDispatch<AppDispatch>();
  const [timeZone, setTimeZone] = useState<TimeZoneInfo | null>(null);
  const [currentTimeInTZ, setCurrentTimeInTZ] = useState<Date | null>(null);

  useEffect(() => {
    dispatch(fetchOperatingHours(serviceCode));
  }, [dispatch, serviceCode]);

  useEffect(() => {
    if (operatingHours && operatingHours.timeZone) {
      setTimeZone(operatingHours.timeZone);
      const zonedDate = toZonedTime(new Date(), operatingHours.timeZone.name);
      setCurrentTimeInTZ(zonedDate);
      setLoading(false);
    }
  }, [operatingHours]);

  useEffect(() => {
    if (
      operatingHours &&
      operatingHours.days.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const today = new Date(currentTimeInTZ);
      const next7Days = getNext7DaysWithDates(operatingHours.days, today);
      const selectedDay = next7Days[0];
      const selectedDate = selectedDay.date;
      const formattedDate = isSameDay(selectedDate, new Date())
        ? `Today, ${format(selectedDate, "MMMM d")}`
        : format(selectedDate, "EEEE, MMMM d");

      if (selectedDay && selectedDay.shifts.length > 0) {
        let allSlots = generateLaundrySlots(selectedDay.shifts);

        const today = new Date(currentTimeInTZ);
        const isToday =
          format(selectedDay.date, "yyyy-MM-dd") ===
          format(today, "yyyy-MM-dd");
        
        if (isToday) {
          allSlots = applyPickupMinsToToday(
            allSlots,
            today,
            operatingHours.pickupMins,
            false
          );
        }
        setPickupDate(formattedDate);
        setPickupTime(allSlots[0] ? allSlots[0] : generateLaundrySlots(selectedDay.shifts)[0]);
      }
    }
  }, [operatingHours]);

  useEffect(() => {
    fetchTimes();
  }, [pickupDate]);

  const fetchTimes = async () => {
    try {
      if(currentTimeInTZ && operatingHours?.dropoffMins) {
        const todayNameInTZ = format(currentTimeInTZ, "EEEE");
  
      const normalizedPickupDate = pickupDate.includes("Today")
        ? pickupDate.replace("Today", todayNameInTZ)
        : pickupDate;
  
        const pickupDateParsed = parse(normalizedPickupDate, "EEEE, MMMM d", new Date());
        const pr = addMinutes(pickupDateParsed, operatingHours.dropoffMins)
        const next7Days = getNextDropOff7DaysWithDates(operatingHours.days, pr);
        const selectedDay = next7Days[0];
        
        let extraShift = ["Anytime during the day"];
        const to = parse(
          selectedDay.shifts[0].toTime,
          "HH:mm:ss",
          new Date()
        );
        if (to < parse("20:00:00", "HH:mm:ss", new Date()))
          extraShift.push("Anytime before 8:00 PM");

        let allSlots = generateLaundryDroppoffSlots(selectedDay.shifts);

        allSlots = extraShift.concat(allSlots);
  
        setDropOffDate(format(selectedDay.date, "EEEE, MMMM d"))
        setDropOffTime(allSlots[0]);
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setDropOffmodalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);

  useEffect(() => {
    if (pickupshouldAnimateOut) {
      const timer = setTimeout(() => {
        setPickupmodalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [pickupshouldAnimateOut]);

  const openDropoffModal = () => {
    setDropOffmodalIsOpen(true);
    setShouldAnimateOut(false);
  };
  const closeDropoffModal = () => {
    setShouldAnimateOut(true);
  };

  const openPickupModal = () => {
    setPickupmodalIsOpen(true);
    setPickupShouldAnimateOut(false);
  };
  const closePickupModal = () => {
    setPickupShouldAnimateOut(true);
  };


  const handlePickupTime = (pickDate: string, pickTime: string) => {
    setPickupDate(pickDate);
    setPickupTime(pickTime);
    setIsPickupTime(false);
  };

  const handleDropOffTime = (dropoffDate: string, dropoffTime: any) => {
    setDropOffDate(dropoffDate);
    setDropOffTime(dropoffTime);
    setIsDropOffTime(false);
  };

  const onSubmit = () => {
    if (!dropOffTime || !pickupTime) return;
    onContinue({ pickupDate, pickupTime }, { dropOffDate, dropOffTime });
  };

  return (
    <div className={styles.selectTimeC}>
      <header>
        <div
          className={`flex ${styles.upper} place-content-between items-center `}
        >
          <span onClick={() => onBack()}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6L8 12.1222L14 18.2444"
                stroke="#323E48"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </svg>
          </span>
        </div>
        <div className={styles.title}>
          <h1>Confirm timings</h1>
          <p>We’ve pre-selected the earliest available slots</p>
        </div>
      </header>
      {!(isPickupTime || isDropOffTime) && (
        <>
          <div className={styles.laundrySections}>
            {loading ? (
              <div className={`${styles.section}`}>{threeRowTexts()}</div>
            ) : (
              <div onClick={openPickupModal} className={`${styles.section}`}>
                <div className={`flex  ${styles.sectionData}`}>
                  <div className={styles.timeData}>
                    <p>Pick up</p>
                    <p>{pickupDate}</p>
                    <p>{pickupTime}</p>
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <div className={`${styles.section}`}>{threeRowTexts()}</div>
            ) : (
              <div onClick={openDropoffModal} className={`${styles.section}`}>
                <div className={`flex  ${styles.sectionData}`}>
                  <div className={styles.timeData}>
                    <p>Drop off</p>
                    <p>{dropOffDate}</p>
                    <p>{dropOffTime && dropOffTime}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <button onClick={onSubmit}>Continue</button>
          </div>
        </>
      )}
      <PickupTimes
        shouldAnimateOut={pickupshouldAnimateOut}
        isOpen={pickupmodalIsOpen}
        onRequestClose={closePickupModal}
        onConfirm={handlePickupTime}
      />
      {pickupDate && (
        <DropoffTimes
          shouldAnimateOut={shouldAnimateOut}
          isOpen={dropOffmodalIsOpen}
          onRequestClose={closeDropoffModal}
          pickupDate={pickupDate}
          onConfirm={handleDropOffTime}
        />
      )}
    </div>
  );
};

export default LaundrySelectTime;
