import React, { useEffect, useState } from "react";
import styles from "../../styles/cleaningService/payment.module.css";
import axios from "axios";
import { cancellationPolicy } from "../../data/cleaningService/faq";
import PaymentDropdown from "../PaymentDropdown";
import PaymentCard from "./stripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PhoneModal from "../phoneModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface PaymentProps {
  timeData: { dateSelected: string; timeSelected: string } | null;
  onBack: () => void;
}

const Payment: React.FC<PaymentProps> = ({ onBack, timeData }) => {
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [checkoutData, setCheckoutData] = useState<any>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const phoneNumber: string | null = localStorage.getItem("phoneNumber");
  const parsedPhoneNumber = phoneNumber ? JSON.parse(phoneNumber) : null;
  const [formatedDate, setFormatedDate] = useState<any>(undefined);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  
  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300); // Match the duration of the slide-out animation
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);
  

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/housekeeping/checkout`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setCheckoutData(response.data);
      } catch (error) {
        console.error("There was an error fetching the services!", error);
      }
    };

    fetchServices();
  }, []);

  const handlePaymentMethodChange = (method: string, label: string) => {
    setSelectedMethod(method);
    setSelectedLabel(label);
  };

  useEffect(() => {
    setFormatedDate(convertDateTime());
  }, [timeData]);

  const convertDateTime = () => {
    if (!timeData?.timeSelected || !timeData.dateSelected) return;


    const [time, period] = timeData?.timeSelected.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
      
    return { formattedDate: timeData.dateSelected, formattedTime };
  };

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.backArrow} onClick={onBack}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
      <div className={styles.checkOut}>
        <h1>Checkout</h1>
        <div className={styles.service}>
          <h4>Service</h4>
          <div className={` flex ${styles.serviceDetail}`}>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5843 20.0033H4.3341C3.8138 20.0033 3.38036 19.6045 3.33715 19.086L2.50346 9.08182C2.48022 8.80302 2.5748 8.52722 2.76423 8.32135C2.95367 8.11547 3.22065 7.99832 3.50042 7.99832H15.0051"
                  stroke="#8B98A4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5041 7.99833V6.93209C12.5041 4.99829 10.9364 3.43063 9.00262 3.43063V3.43063C7.06882 3.43063 5.50116 4.99829 5.50116 6.93209V7.99833"
                  stroke="#8B98A4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7866 15.0013H14.225C13.2712 15.0013 12.45 15.6745 12.263 16.6097L11.5235 20.3073C11.4647 20.6012 11.5408 20.906 11.7308 21.1378C11.9208 21.3696 12.2047 21.504 12.5044 21.504H20.5071C20.8068 21.504 21.0907 21.3696 21.2808 21.1378C21.4708 20.906 21.5469 20.6012 21.4881 20.3073L20.7485 16.6097C20.5615 15.6745 19.7403 15.0013 18.7866 15.0013Z"
                  stroke="#8B98A4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.5058 2.49603V2.49603C17.3345 2.49603 18.0064 3.16789 18.0064 3.99666V14.501C18.0064 14.7773 17.7824 15.0012 17.5062 15.0012H15.5053C15.2291 15.0012 15.0051 14.7773 15.0051 14.501V3.99666C15.0051 3.16789 15.677 2.49603 16.5058 2.49603Z"
                  stroke="#8B98A4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0271 18.0025H11.9844"
                  stroke="#8B98A4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div>
              <p>Housekeeping</p>
              <p>This service will take approximately 2 hrs</p>
            </div>
          </div>
        </div>
        <div className={styles.details}>
          <h4>Details</h4>
          <div className={`${styles.serviceDetail}`}>
            <div className={`flex items-center  ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.25 19.85C12.25 19.85 5.95001 14.675 5.95001 9.94999C5.95001 6.47059 8.77061 3.64999 12.25 3.64999C15.7294 3.64999 18.55 6.47059 18.55 9.94999C18.55 14.675 12.25 19.85 12.25 19.85Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2501 12.4251V12.4251C10.8831 12.4251 9.77509 11.3171 9.77509 9.9501V9.9501C9.77509 8.58307 10.8831 7.4751 12.2501 7.4751V7.4751C13.6171 7.4751 14.7251 8.58307 14.7251 9.9501V9.9501C14.7251 11.3171 13.6171 12.4251 12.2501 12.4251Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                {checkoutData ? (
                  `${checkoutData.departmentNumber}, ${checkoutData.buildingName}, ${checkoutData.Area.name.toLowerCase()}`
                ) : (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                )}
              </p>
            </div>
            <div className={`flex items-center  ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.87326 3.74658V6.49773"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.1267 3.74658V6.49773"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.74658 9.70736H20.2535"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="3.74658"
                    y="5.12213"
                    width="16.5069"
                    height="15.1313"
                    rx="3"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>{timeData?.dateSelected}</p>
            </div>
            <div className={`flex items-center  ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.2619 20.7738C16.9629 20.7738 20.7738 16.9629 20.7738 12.2619C20.7738 7.56091 16.9629 3.75 12.2619 3.75C7.56091 3.75 3.75 7.56091 3.75 12.2619C3.75 16.9629 7.56091 20.7738 12.2619 20.7738Z"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.2618 8.98816V12.262L15.588 16.1382"
                    stroke="#8B98A4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>{timeData?.timeSelected}</p>
            </div>
            {parsedPhoneNumber &&
              <div onClick={openModal} className={`flex items-center justify-between ${styles.detailsItem}`}> 
                <div className={`flex items-center`}>
                  <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10 18H14" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <p className="ml-2">{parsedPhoneNumber.number}</p>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16L15 12L11 8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            }
          </div>
        </div>
        <div className={styles.additionalNotes}>
          <h4>Additional notes</h4>
          <div className={` flex ${styles.serviceDetail}`}>
            <input
              type="text"
              placeholder="Any additional notes"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.paymentSummary}>
          <h4>Payment summary</h4>
          <div className={` ${styles.serviceDetail}`}>
            <div className={styles.paymentMethod}>
              <PaymentDropdown
                selectedLabel={selectedLabel}
                selectedMethod={selectedMethod}
                onChange={handlePaymentMethodChange}
              />
            </div>
            <div className={styles.amounts}>
              <div className={` flex justify-between ${styles.subTotal}`}>
                <p>Subtotal</p>
                {checkoutData ? (
                  <p>{checkoutData.currency.currency} {checkoutData.price}</p>
                ) : (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                )}
              </div>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                <p>Delivery fee</p>
                {checkoutData ? (
                  <p>{checkoutData.currency.currency} {checkoutData.deliveryFee}</p>
                ) : (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                )}
              </div>
              <div className={` flex justify-between ${styles.total}`}>
                <p>Total amount</p>
                {checkoutData ? (
                  <p>{checkoutData.currency.currency} {checkoutData.total}</p>
                ) : (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cancellationPolicy}>
          <h4>Cancellation policy</h4>
          <div className={` flex ${styles.serviceDetail}`}>
            <div className={styles.contentTable}>
              <div className={`flex ${styles.dataContent}`}>
                <p className={` w-1/2 ${styles.header}`}>Time</p>
                <p className={styles.header}>Cancellation fee</p>
              </div>
              {cancellationPolicy.map((data, index) => (
                <div key={index}>
                  <div className={`flex ${styles.dataContent}`}>
                    <p className={` w-1/2  ${styles.tableTimePolicy}`}>
                      {data.time}
                    </p>
                    <p className={`w-1/2  ${styles.tablefeePolicy} `}>
                      {data.fee}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.extaData}></div>
        </div>
        {checkoutData && (
          <div className={styles.footer}>
            {parsedPhoneNumber && formatedDate ? (
              <Elements stripe={stripePromise}>
                <PaymentCard
                  additionalNotes={additionalNotes}
                  balance={checkoutData.total}
                  phoneNumber={parsedPhoneNumber}
                  timeData={formatedDate}
                  paymentMethod={selectedMethod}
                  currency={checkoutData.currency}
                />
              </Elements>
            ) : (
              <button className={styles.phoneButton} onClick={openModal}>Continue</button>
            )}
          </div>
        )}
      </div>
      <PhoneModal shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default Payment;
