import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/selectTime.module.css";
import { twoRowTexts2 } from "../../utils/skeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperatingHours } from "../../redux/operatingHoursSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { format, addDays, parse, addMinutes, isSameDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { fetchServices } from "../../redux/serviceSlice";
import { Day, DayWithDate, Shift, TimeZoneInfo } from "../../utils/types/times";
import { applyPickupMinsToToday, generateHouskeepingSlots, getNext7DaysWithDates } from "../../utils/times/operatingHours";

interface SelectTimeProps {
  onContinue: (TimeData: {
    dateSelected: string;
    timeSelected: string;
  }) => void;
}

const SelectTime: React.FC<SelectTimeProps> = ({ onContinue }) => {
  const code: number = 1;
  const [dateSelected, setDateSelected] = useState<number>(0);
  const [timeSelected, setTimeSelected] = useState<string>("");
  const [processedDays, setProcessedDays] = useState<DayWithDate[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [timeZone, setTimeZone] = useState<TimeZoneInfo | null>(null);
  const [currentTimeInTZ, setCurrentTimeInTZ] = useState<Date | null>(null);
  const [loading, setLoading] = useState<Boolean>(true);
  const { operatingHours, error } = useSelector(
    (state: any) => state.operatingHours
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { services } = useSelector((state: RootState) => state.services);
  const [service, setService] = useState<any>(null);

  useEffect(() => {
    setService(
      services.find((service) => {
        if (service.service && service.service.code === code) {
          return true;
        }
        return service.code === code;
      })
    );
  }, [services]);

  useEffect(() => {
    dispatch(fetchOperatingHours(code));
    dispatch(fetchServices());

  }, [dispatch, code]);


  useEffect(() => {
    if (operatingHours && operatingHours.timeZone) {
      setTimeZone(operatingHours.timeZone);
      const zonedDate = toZonedTime(new Date(), operatingHours.timeZone.name);
      setCurrentTimeInTZ(zonedDate);
      setLoading(false);
    }
  }, [operatingHours]);


  useEffect(() => {
    if (
      operatingHours &&
      operatingHours.days.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const today = new Date(currentTimeInTZ);
      const next7Days = getNext7DaysWithDates(operatingHours.days, today);

      if (next7Days.length === 0) {
        setProcessedDays([]);
        return;
      }

      const todayDay = next7Days[0];
      const allSlotsToday = generateHouskeepingSlots(todayDay.shifts);
      const filteredSlotsToday = applyPickupMinsToToday(
        allSlotsToday,
        today,
        operatingHours.pickupMins,
        true
      );

      if (filteredSlotsToday.length === 0) {
        const reorderedDays = [...next7Days.slice(1)];
        setProcessedDays(reorderedDays);
      } else {
        setProcessedDays(next7Days);
      }
    }
  }, [operatingHours, currentTimeInTZ, timeZone]);

 
  const generateDates = () => {
    const dates: string[] = [];
    const today = currentTimeInTZ ? new Date(currentTimeInTZ) : new Date();

    processedDays.forEach((dayWithDate) => {
        const date = dayWithDate.date;
        const isTodayFlag = isSameDay(date, today);
        const formattedDate = format(date, 'MMMM d'); //.g., "November 20" e
        const dateString = isTodayFlag ? `Today, ${formattedDate}` : format(date, 'EEEE, MMMM d');
        dates.push(dateString);
    });

    return dates;
};


  useEffect(() => {
    if (
      operatingHours &&
      processedDays.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const selectedDay = processedDays[dateSelected];
      if (selectedDay && selectedDay.shifts.length > 0) {
        let allSlots = generateHouskeepingSlots(selectedDay.shifts);

        const today = new Date(currentTimeInTZ);
        const isToday =
          format(selectedDay.date, "yyyy-MM-dd") ===
          format(today, "yyyy-MM-dd");

        if (isToday) {
          allSlots = applyPickupMinsToToday(
            allSlots,
            today,
            operatingHours.pickupMins,
            true
          );
        }

        setTimes(allSlots);
        setTimeSelected(allSlots[0] || "");
      } else {
        setTimes([]);
        setTimeSelected("");
      }
    }
  }, [dateSelected, operatingHours, currentTimeInTZ, processedDays, timeZone]);

 
  const handleContinueClick = () => {
    if (processedDays[dateSelected] && timeSelected) {
      const selectedDay = processedDays[dateSelected];
      const selectedDate = selectedDay.date;
      const dateString = format(selectedDate, "yyyy-MM-dd");
      
      onContinue({ dateSelected: dateString, timeSelected });
    }
  };


  const handleDateClick = (index: number) => {
    setDateSelected(index);
  };

  const handleTimeClick = (value: string) => {
    setTimeSelected(value);
  };
  
  return (
    <div className={styles.container}>
      <header>
        <div
          className={`flex ${styles.upper} place-content-between items-center `}
        >
          <span onClick={() => navigate("/app")}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6L8 12.1222L14 18.2444"
                stroke="#323E48"
                strokeWidth="3"
                strokeMiterlimit="10"
              />
            </svg>
          </span>
          <Link to="/app/housekeeping/info">Learn more</Link>
        </div>
        <div className={styles.title}>
          <h1>Select start time</h1>
          <p>This service will take approximately {service && service.duration / 60} hours</p>
        </div>
      </header>
      <div className={`flex ${styles.select} ${styles.cleaningTime}`}>
        <div className={`${styles.date}`}>
          {loading ? (
            <>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
            </>
          ) : (
            <>
              {generateDates().map((date: string, index: number) => (
                <div
                  key={index}
                  onClick={() => handleDateClick(index)}
                  className={`${styles.selectBox} ${
                    dateSelected === index ? `${styles.selected}` : ""
                  }`}
                >
                  <p>{date.split(", ")[0]}</p>
                  <p>{date.split(", ")[1]}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={`${styles.time}`}>
          {loading ? (
            <>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
              <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
            </>
          ) : (
            <>
              {times.length > 0 ? (
                times.map((time: string, index: number) => (
                  <div
                    key={index}
                    onClick={() => handleTimeClick(time)}
                    className={`${styles.selectBox} ${
                      timeSelected === time ? `${styles.selected}` : ""
                    }`}
                  >
                    <p>{time}</p>
                  </div>
                ))
              ) : (
                <p>No available time slots for this day.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <button onClick={handleContinueClick} disabled={!timeSelected}>
          Continue to checkout
        </button>
      </div>
    </div>
  );
};

export default SelectTime;