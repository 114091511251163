import React, { useEffect, useState } from "react";
import styles from '../../styles/towelService/payment.module.css'
import axios from "axios";
import PaymentDropdown from "../PaymentDropdown";
import PaymentCard from "./stripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PhoneModal from "../phoneModal";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { twoRowTexts } from "../../utils/skeleton";

interface PaymentProps {
    towelsData: { id: number; count: number; } | null;
    onBack: () => void

  }
  
  const Payment: React.FC<PaymentProps> = ({onBack, towelsData }) => {
    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [additionalNotes, setAdditionalNotes] = useState<string>('');
    const [selectedLabel, setSelectedLabel] = useState<string>('');
    const [checkoutData, setCheckoutData] = useState<any>(undefined)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
    const phoneNumber: string | null = localStorage.getItem("phoneNumber");
    const parsedPhoneNumber = phoneNumber ? JSON.parse(phoneNumber) : null;    const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

    const openModal = () => {
      setModalIsOpen(true);
      setShouldAnimateOut(false);
    };
  
    const closeModal = () => {
      setShouldAnimateOut(true);
    };
  
    useEffect(() => {
      if (shouldAnimateOut) {
        const timer = setTimeout(() => {
          setModalIsOpen(false);
        }, 300); // Match the duration of the slide-out animation
        return () => clearTimeout(timer);
      }
    }, [shouldAnimateOut]);

    
    useEffect(() => {
        const fetchServices = async () => {
          try {
            const token = localStorage.getItem('PropertyToken');
            if (!token) {
              throw new Error('PropertyToken not found');
            }
    
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}services/freshTowels/checkout`, {
                params: {
                  id: towelsData?.id,
                  count: towelsData?.count
                },
                headers: {
                  propertyToken: token
                }
              });
            setCheckoutData(response.data);
          } catch (error) {
            console.error('There was an error fetching the services!', error);
          }
        };
    
        fetchServices();
    
      }, []);

    const handlePaymentMethodChange = (method: string, label: string) => {
        setSelectedMethod(method);
        setSelectedLabel(label);
    };


    return (
        <div className={styles.container}>
            <div className={styles.upper}>
                <div className={styles.backArrow} onClick={onBack}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
                </svg>
                </div>
            </div>
            <div className={styles.checkOut}>
                <h1>Checkout</h1>
                <div className={styles.service}>
                    <h4>Service</h4>
                    <div className={` flex ${styles.serviceDetail}`}>
                        <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6V5C19 3.895 18.105 3 17 3V3C15.895 3 15 3.895 15 5V19C15 20.105 14.105 21 13 21H7C5.895 21 5 20.105 5 19V6C5 4.343 6.343 3 8 3H17" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19 9V15C19 16.105 18.105 17 17 17H15" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 9H20.5C21.328 9 22 8.328 22 7.5V7.5C22 6.672 21.328 6 20.5 6H15" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 6H3.5C2.672 6 2 6.672 2 7.5V7.5C2 8.328 2.672 9 3.5 9H5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>


                        </span>
                        <div>
                            {checkoutData ? (
                                <>
                                    <p>Fresh towel delivery</p>
                                    <p>{checkoutData.currency.currency} {checkoutData.price}</p>
                                </>
                            ):(
                                <>
                                {twoRowTexts()}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.details}>
                    <h4>Details</h4>
                        <div className={`${styles.serviceDetail}`}>
                            <div className={`flex ${styles.detailsItem}`}>
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.25 19.85C12.25 19.85 5.95001 14.675 5.95001 9.94999C5.95001 6.47059 8.77061 3.64999 12.25 3.64999C15.7294 3.64999 18.55 6.47059 18.55 9.94999C18.55 14.675 12.25 19.85 12.25 19.85Z" stroke="#8B98A4" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2501 12.4251V12.4251C10.8831 12.4251 9.77509 11.3171 9.77509 9.9501V9.9501C9.77509 8.58307 10.8831 7.4751 12.2501 7.4751V7.4751C13.6171 7.4751 14.7251 8.58307 14.7251 9.9501V9.9501C14.7251 11.3171 13.6171 12.4251 12.2501 12.4251Z" stroke="#8B98A4" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <p>{checkoutData ? `${checkoutData.departmentNumber}, ${checkoutData.buildingName}, ${checkoutData.Area.name.toLowerCase()}`: <Skeleton height={20} width={100}  baseColor="#F2F5F8" highlightColor="#fff"/>}</p>
                            </div>
                            <div className={`flex ${styles.detailsItem}`}>
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.2619 20.7738C16.9629 20.7738 20.7738 16.9629 20.7738 12.2619C20.7738 7.56091 16.9629 3.75 12.2619 3.75C7.56091 3.75 3.75 7.56091 3.75 12.2619C3.75 16.9629 7.56091 20.7738 12.2619 20.7738Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.2618 8.98816V12.262L15.588 16.1382" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <p>Delivery in {checkoutData?.estimate}</p>
                            </div>
                            {parsedPhoneNumber &&
                                <div onClick={openModal} className={`flex items-center justify-between ${styles.detailsItem}`}> 
                                    <div className={`flex items-center`}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 18H14" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    <p className="ml-2">{parsedPhoneNumber.number}</p>
                                    </div>
                                    <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 16L15 12L11 8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    </div>
                                </div>
                                }
                        </div>
                </div>
                <div className={styles.additionalNotes}>
                    <h4>Additional notes</h4>
                    <div className={` flex ${styles.serviceDetail}`}>
                    <input
                        type="text"
                        placeholder="Any additional notes"
                        value={additionalNotes}
                        onChange={(e) => setAdditionalNotes(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.paymentSummary}>
                    <h4>Payment summary</h4>
                    <div className={` ${styles.serviceDetail}`}>
                        <div className={styles.paymentMethod}>
                            <PaymentDropdown selectedLabel={selectedLabel} selectedMethod={selectedMethod} onChange={handlePaymentMethodChange} />
                        </div>
                        <hr/>
                        <div className={styles.amounts}>
                            <div className={` flex justify-between ${styles.subTotal}`}>
                                <p>x{towelsData?.count} Fresh towel</p>
                                {checkoutData ? <p>{checkoutData.currency.currency} {checkoutData.price}</p> : <Skeleton height={20} width={100}  baseColor="#F2F5F8" highlightColor="#fff"/>}
                            </div>
                            <hr/>
                            <div className={` flex justify-between ${styles.subTotal}`}>
                                <p>subTotal</p>
                                {checkoutData ? <p>{checkoutData.currency.currency} {checkoutData.price}</p> : <Skeleton height={20} width={100}  baseColor="#F2F5F8" highlightColor="#fff"/>}
                            </div>
                            <div className={` flex justify-between ${styles.deliveryFee}`}>
                                <p>Delivery fee</p>
                                {checkoutData ? <p>{checkoutData.currency.currency} {checkoutData.deliveryFee}</p> : <Skeleton height={20} width={100}  baseColor="#F2F5F8" highlightColor="#fff"/>}
                            </div>
                            <hr/>
                            <div className={` flex justify-between ${styles.total}`}>
                                <p>Total amount</p>
                                {checkoutData ? <p>{checkoutData.currency.currency} {checkoutData.total}</p> : <Skeleton height={20} width={100}  baseColor="#F2F5F8" highlightColor="#fff"/>}
                            </div>
                        </div>
                        <div className={styles.extaData}>

                        </div>
                    </div>
                </div>
                { checkoutData &&
                    <div className={styles.footer}>
                        { (parsedPhoneNumber && checkoutData && towelsData) ? (
                                <Elements stripe={stripePromise}>
                                    <PaymentCard additionalNotes={additionalNotes} currency={checkoutData.currency} balance={checkoutData.total} phoneNumber={parsedPhoneNumber} towelsData={towelsData} paymentMethod={selectedMethod} />
                                </Elements>
                            ) : (
                                <button onClick={openModal}>Continue</button>  
                            )
                        }
                    </div>
                }
            </div>
            <PhoneModal shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />
            </div>
    );
  };
  
export default Payment;