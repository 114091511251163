// src/components/PickupTimes.tsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/selectTime.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperatingHours } from "../../redux/operatingHoursSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { toZonedTime } from "date-fns-tz";
import Modal from "react-modal";
import {
  format,
  parse,
  addMinutes,
} from "date-fns";
import { Day, DayWithDate, Shift, TimeZoneInfo } from "../../utils/types/times";
import { generateLaundryDroppoffSlots, getNextDropOff7DaysWithDates } from "../../utils/times/operatingHours";


interface DropoffTimeProps {
  onConfirm: (dropoffDate: string, dropoffTime: string) => void;
  pickupDate: string; // e.g., "Friday, November 22"
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean;
}

const DropoffTimes: React.FC<DropoffTimeProps> = ({
  pickupDate,
  onConfirm,
  isOpen,
  onRequestClose,
  shouldAnimateOut,
}) => {
  const serviceCode: number = 2; // Changed from 1 to 2
  const [dateSelected, setDateSelected] = useState<number>(0);
  const [timeSelected, setTimeSelected] = useState<string>("");
  const [processedDays, setProcessedDays] = useState<DayWithDate[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [timeZone, setTimeZone] = useState<TimeZoneInfo | null>(null);
  const [currentTimeInTZ, setCurrentTimeInTZ] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  const { operatingHours, error } = useSelector(
    (state: RootState) => state.operatingHours
  );

  useEffect(() => {
    dispatch(fetchOperatingHours(serviceCode));
  }, [dispatch, serviceCode]);

  useEffect(() => {
    if (
      operatingHours &&
      operatingHours.timeZone &&
      pickupDate &&
      operatingHours.dropoffMins !== null
    ) {
      setTimeZone(operatingHours.timeZone);

      const zonedNow = toZonedTime(new Date(), operatingHours.timeZone.name);

      const todayNameInTZ = format(zonedNow, "EEEE");

      const normalizedPickupDate = pickupDate.includes("Today")
        ? pickupDate.replace("Today", todayNameInTZ)
        : pickupDate;

      const pickupDateParsed = parse(
        normalizedPickupDate,
        "EEEE, MMMM d",
        new Date()
      );

      if (isNaN(pickupDateParsed.getTime())) {
        return;
      }

      const baseDate = addMinutes(pickupDateParsed, operatingHours.dropoffMins);
      const zonedDate = toZonedTime(baseDate, operatingHours.timeZone.name);

      setCurrentTimeInTZ(zonedDate);
      setLoading(false);
    }
  }, [operatingHours, pickupDate]);

  useEffect(() => {
    if (
      operatingHours &&
      operatingHours.days.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const baseDate = new Date(currentTimeInTZ);

      const next7Days = getNextDropOff7DaysWithDates(
        operatingHours.days,
        baseDate
      );

      if (next7Days.length === 0) {
        setProcessedDays([]);
        return;
      }

      setProcessedDays(next7Days);
    }
  }, [operatingHours, currentTimeInTZ, timeZone]);

  const generateDates = () => {
    const dates: string[] = [];

    processedDays.forEach((dayWithDate) => {
      const date = dayWithDate.date;
      dates.push(format(date, "EEEE, MMMM d"));
    });

    return dates;
  };

  useEffect(() => {
    if (
      operatingHours &&
      processedDays.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const selectedDay = processedDays[dateSelected];
      if (selectedDay && selectedDay.shifts.length > 0) {
        let extraShift = ["Anytime during the day"];
        const to = parse(
          selectedDay.shifts[0].toTime,
          "HH:mm:ss",
          new Date()
        );
        if (to < parse("20:00:00", "HH:mm:ss", new Date()))
          extraShift.push("Anytime before 8:00 PM");

        let allSlots = generateLaundryDroppoffSlots(selectedDay.shifts);
      
        allSlots = extraShift.concat(allSlots);

        setTimes(allSlots);
        setTimeSelected(allSlots[0] || "");
      } else {
        setTimes([]);
        setTimeSelected("");
      }
    }
  }, [dateSelected, operatingHours, currentTimeInTZ, processedDays, timeZone]);

  const handleConfirm = () => {
    if (processedDays[dateSelected] && timeSelected) {
      const selectedDay = processedDays[dateSelected];
      const selectedDate = selectedDay.date;

      onConfirm(format(selectedDate, "EEEE, MMMM d"), timeSelected);
      onRequestClose();
    }
  };

  const handleDateClick = (index: number) => {
    setDateSelected(index);
  };

  const handleTimeClick = (value: string) => {
    setTimeSelected(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.modalContent} ${
        shouldAnimateOut ? styles.exit : ""
      }`}
      overlayClassName={`${styles.modalOverlay} ${
        shouldAnimateOut ? styles.exitOverlay : ""
      }`}
    >
      <div className={`flex flex-col ${styles.container}`}>
        <h4>Select drop-off date and time</h4>
        <div className={`flex ${styles.select} ${styles.modalselect}`}>
          <div className={`${styles.date}`}>
            {loading ? (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className={styles.skeletonLoading}>
                    {/* Placeholder for loading */}
                    <div className={styles.skeletonBox}></div>
                  </div>
                ))}
              </>
            ) : error ? (
              <div className={styles.error}>
                <p>Error: {error}</p>
                <button
                  onClick={() => dispatch(fetchOperatingHours(serviceCode))}
                >
                  Retry
                </button>
              </div>
            ) : processedDays.length === 0 ? (
              <p>No services available in the next 7 days.</p>
            ) : (
              <>
                {generateDates().map((date: string, index: number) => (
                  <div
                    key={index}
                    onClick={() => handleDateClick(index)}
                    className={`${styles.selectBox} ${
                      dateSelected === index ? styles.selected : ""
                    }`}
                  >
                    <p>{date.split(", ")[0]}</p>
                    <p>{date.split(", ")[1]}</p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={`${styles.time}`}>
            {loading ? (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className={styles.skeletonLoading}>
                    {/* Placeholder for loading */}
                    <div className={styles.skeletonBox}></div>
                  </div>
                ))}
              </>
            ) : times.length > 0 ? (
              times.map((time: string, index: number) => (
                <div
                  key={index}
                  onClick={() => handleTimeClick(time)}
                  className={`${styles.selectBox} ${
                    timeSelected === time ? styles.selected : ""
                  }`}
                >
                  <p>{time}</p>
                </div>
              ))
            ) : (
              <p>No available time slots for this day.</p>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <button onClick={handleConfirm} disabled={!timeSelected}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DropoffTimes;
