import { format, parse, addMinutes, isAfter, addDays, isSameDay, isEqual, isBefore } from 'date-fns';
import { Day, DayWithDate, Shift, TimeZoneInfo } from "../types/times";


// Housekeeping
export const generateHouskeepingSlots = (shifts: Shift[]): string[] => {
  const slots: string[] = [];

  shifts.forEach((shift) => {
    if (!shift.isAvailable) return;

    const from = parse(shift.fromTime, "HH:mm:ss", new Date());
    const to = parse(shift.toTime, "HH:mm:ss", new Date());

    let current = from;

    while (current <= to) {
      slots.push(format(current, "h:mm a"));
      current = addMinutes(current, 30);
    }
  });

  return Array.from(new Set(slots)).sort((a, b) => {
    const timeA = parse(a, "h:mm a", new Date());
    const timeB = parse(b, "h:mm a", new Date());
    return timeA.getTime() - timeB.getTime();
  });
};

// Laundry 
export const generateLaundrySlots = (shifts: Shift[]): string[] => {
    const slots: string[] = [];
  
    shifts.forEach((shift) => {
      if (!shift.isAvailable) return;
  
      const from = parse(shift.fromTime, "HH:mm:ss", new Date());
      const to = parse(shift.toTime, "HH:mm:ss", new Date());
  
      let current = from;
  
      while (isBefore(current, to)) {
        const slotEnd = addMinutes(current, 120);
        if (isAfter(slotEnd, to)) break;
  
        const slotString = `${format(current, "h:mm a")} - ${format(slotEnd, "h:mm a")}`;
        slots.push(slotString);
  
        current = addMinutes(current, 60);
      }
    });
  
    const uniqueSlots = Array.from(new Set(slots));
  
    uniqueSlots.sort((a, b) => {
      const [aStart] = a.split(" - ");
      const [bStart] = b.split(" - ");
  
      const timeA = parse(aStart, "h:mm a", new Date());
      const timeB = parse(bStart, "h:mm a", new Date());
  
      return timeA.getTime() - timeB.getTime();
    });
  
    return uniqueSlots;
  };


 export const generateLaundryDroppoffSlots = (shifts: Shift[]): string[] => {
    const slots: string[] = [];
  
    shifts.forEach((shift) => {
      if (!shift.isAvailable) return;
  
      const from = parse(shift.fromTime, "HH:mm:ss", new Date());
      const to = parse(shift.toTime, "HH:mm:ss", new Date());
  
      let current = from;
  
      while (isBefore(current, to)) {
        const slotStart = current;
        const slotEnd = addMinutes(current, 30); 
  
        if (isAfter(slotEnd, to)) break;
  
        slots.push(`${format(slotStart, "h:mm a")} - ${format(slotEnd, "h:mm a")}`);
        current = addMinutes(current, 60);
      }
    });
  
    return Array.from(new Set(slots)).sort((a, b) => {
      const timeA = parse(a.split(" - ")[0], "h:mm a", new Date());
      const timeB = parse(b.split(" - ")[0], "h:mm a", new Date());
      return timeA.getTime() - timeB.getTime();
    });
  };
  

// Shared
export const getNext7DaysWithDates = (days: Day[], today: Date): DayWithDate[] => {
    const daysWithDates: DayWithDate[] = [];
    for (let i = 0; i < days.length; i++) {
      const date = addDays(today, i);
      const dayName = format(date, "EEEE"); // "Monday", "Tuesday", etc.
      const dayData = days.find((d) => d.day === dayName);
      if (dayData && !dayData.isDisabled) {
        daysWithDates.push({ ...dayData, date });
      }
    }
    return daysWithDates;
  };



 export const getNextDropOff7DaysWithDates = (days: Day[], baseDate: Date): DayWithDate[] => {
    const daysWithDates: DayWithDate[] = [];
    
    for (let i = 0; daysWithDates.length < days.length && i < days.length; i++) { 
      const date = addDays(baseDate, i);
      const dayName = format(date, "EEEE");
      
      const dayData = days.find((d) => d.day === dayName);
      if (dayData && !dayData.isDisabled) {
        daysWithDates.push({ ...dayData, date });
      }
    }  
    return daysWithDates;
  };


  export const applyPickupMinsToToday = (
    slots: string[],
    today: Date,
    pickupMins: number,
    isSingleSlot: boolean
  ): string[] => {
    
    const pickupThreshold = addMinutes(today, pickupMins);
    
    if(isSingleSlot){
      return slots.filter((slot) => {
        const slotTime = parse(slot, "h:mm a", today);
        const slotDateTime = new Date(today);
        slotDateTime.setHours(slotTime.getHours(), slotTime.getMinutes(), 0, 0);
        return slotDateTime >= pickupThreshold;
      });
    } else {
      return slots.filter((slot) => {
        const [startTimeStr, endTimeStr] = slot.split(" - ");
    
        if (!startTimeStr) {
          console.warn(`Invalid slot format: "${slot}"`);
          return false;
        }
    
        const parsedStartTime = parse(startTimeStr, "h:mm a", today);
        
        if (isNaN(parsedStartTime.getTime())) {
          console.warn(`Failed to parse start time: "${startTimeStr}"`);
          return false;
        }
    
        return isAfter(parsedStartTime, pickupThreshold) || isEqual(parsedStartTime, pickupThreshold);
      });
    }
  };