import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define the structure of the operating hours data
interface OperatingHours {
  [key: string]: any; // Replace this with the actual structure if known
}

// Define the structure of the state
interface OperatingHoursState {
  operatingHours: OperatingHours | null;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: OperatingHoursState = {
  operatingHours: null,
  loading: false,
  error: null,
};

// Define the thunk for fetching operating hours
export const fetchOperatingHours = createAsyncThunk<
  OperatingHours,
  number,
  {
    rejectValue: string;
  }
>(
  "operatingHours/fetch",
  async (code, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("PropertyToken");
      if (!token) {
        throw new Error("PropertyToken not found");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}services/operating-hours/${code}`,
        {
          headers: {
            propertyToken: token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data
          : error.message
      );
    }
  }
);

// Create the slice
const operatingHoursSlice = createSlice({
  name: "operatingHours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperatingHours.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchOperatingHours.fulfilled,
        (state, action: PayloadAction<OperatingHours>) => {
          state.loading = false;
          state.operatingHours = action.payload;
        }
      )
      .addCase(
        fetchOperatingHours.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Failed to fetch operating hours";
        }
      );
  },
});

export default operatingHoursSlice.reducer;
