import React, { useEffect, useRef, useState } from "react";
import styles from '../../styles/laundryService/selectItem.module.css'
import {useNavigate } from "react-router-dom";
import axios from "axios";
import {ReactComponent as ReactplusIcon} from '../../assets/icons/plus.svg'
import {ReactComponent as ReactColoredsubIcon} from '../../assets/icons/coloredSub.svg'
import {ReactComponent as ReacttrashIcon} from '../../assets/icons/trash.svg'
import { imgTwoTexts } from "../../utils/skeleton";



type LaundryItem = {
  id: number;
  name: string;
  description: string;
  price: string;
  maxOrderQuantity: number

};

type LaundrySection = {
  id: number;
  name: string;
  description: string;
  laundryItems: LaundryItem[];
  image: string;
};

interface SelectItemProps {
  onContinue: (counts: { [key: number]: number }) => void;
}
const LaundrySelectItem: React.FC<SelectItemProps> = ({ onContinue }) => {
    const navigate = useNavigate()
    const [sections, setSections] = useState<LaundrySection[]>([]);
    const [currency, setCurrency] = useState<any>();

    const [loading, setLoading] = useState<boolean>(true);
    const [openSectionId, setOpenSectionId] = useState<number | null>(null);
    const [counts, setCounts] = useState<{ [key: number]: number }>({});
    const [clickedSections, setClickedSections] = useState<{ [key: number]: boolean }>({});
    const [emptyCounts, setEmptyCounts] = useState<boolean>(true)
    const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});


    useEffect(() => {
      const updateSectionHeights = () => {
        Object.keys(sectionRefs.current).forEach((key) => {
          const sectionElement = sectionRefs.current[key];
          if (sectionElement) {
            document.documentElement.style.setProperty(`--laundry-section-height-${key}`, `${sectionElement.offsetHeight}px`);
          }
        });
      };
      updateSectionHeights();
      window.addEventListener('resize', updateSectionHeights);
      return () => {
        window.removeEventListener('resize', updateSectionHeights);
      };
    }, [sectionRefs, sections]);
    
    const handleSectionClick = (sectionId: number) => {
      setOpenSectionId((prevOpenSectionId) => (prevOpenSectionId === sectionId ? null : sectionId));
      setClickedSections((prevClickedSections) => ({
        ...prevClickedSections,
        [sectionId]: true,
      }));
    };

    const increment = (id: number) => {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [id]: (prevCounts[id] || 0) + 1,
      }));
    };
  
    const decrement = (id: number) => {
      setCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        if (newCounts[id] > 0) {
          newCounts[id] -= 1;
        }
        if (newCounts[id] === 0) {
          delete newCounts[id];
        }
        return newCounts;
      });
    };

    useEffect(() => {
      const storedCounts = localStorage.getItem('counts');
      if (storedCounts) {
        setCounts(JSON.parse(storedCounts));
      }
    }, []);

    useEffect(() => {
      if(JSON.stringify(counts) === '{}')
        setTimeout(() => {
          setEmptyCounts(true)
        }, 200);
      else
        setTimeout(() => {
          setEmptyCounts(false)
        }, 200);
    
      localStorage.setItem('counts', JSON.stringify(counts));
      
    }, [counts]);
    
    useEffect(() => {
      const fetchSections = async () => {
        try {
          const token = localStorage.getItem('PropertyToken');
          if (!token) {
            throw new Error('PropertyToken not found');
          }

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}services/laundry`, {
            headers: {
              propertyToken: token
            }
          });
          setSections(response.data.sections);
          setCurrency(response.data.currency)
          setLoading(false);
        } catch (error) {
          console.error('Error fetching sections with items:', error);
          setLoading(false);
        }
      };
  
      fetchSections();
    }, []);

  
    const calculateTotal = () => {
      return Object.keys(counts).reduce((total, itemId) => {
        const item = sections
          .flatMap(section => section.laundryItems)
          .find(item => item.id === parseInt(itemId));
        const count = counts[parseInt(itemId)];
        return total + (item ? count * parseFloat(item.price) : 0);
      }, 0);
    };


    const getAddedItemsForSection = (section: LaundrySection) => {
      return section.laundryItems.map(item => {
        const count = counts[item.id];
        return count > 0 ? { name: item.name, count } : null;
      }).filter(Boolean);
    };

    const onSubmit = () =>{
      onContinue(counts)
    }
  
  return (
    <div className={styles.selectItemC}>
      <header>
          <div className={`flex ${styles.upper} place-content-between items-center `}>
              <span onClick={()=>{navigate("/app")}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
                </svg>
              </span>
          </div>
          <div className={styles.title}>
              <h1>Select service</h1>
              <p>We will bring you the bags to fill.</p>
          </div>
      </header>

      <div className={styles.laundrySections}>
        {loading ? (
          <>
          {[1, 2, 3, 4].map(() => (
            imgTwoTexts()
          ))}
          </>
        ) : (
          <>
            {sections.map((section) => (
              <div 
                key={section.id}
                onClick={() => handleSectionClick(section.id)}
                ref={(el) => (sectionRefs.current[section.id] = el)}
                style={{ '--laundry-section-height': `var(--laundry-section-height-${section.id})` } as React.CSSProperties}
                className={`${openSectionId === section.id ? styles.active : clickedSections[section.id] ? styles.inActive : ''} ${styles.section}`}>
                <div
                  className={`flex ${styles.sectionData}`}
                >
                  <img src={section.image} alt="" />
                  <div className="flex flex-col justify-center">
                    <h2>{section.name}</h2>
                    <p>{section.description}</p>
                    { openSectionId !== section.id &&
                    <ul className={styles.listAddedItems}>
                      {getAddedItemsForSection(section).map((addedItem: any, index) => (
                        <li key={index}>x{addedItem.count} {addedItem.name}</li>
                      ))}
                    </ul>
                  }
                  </div>
                </div>
                {openSectionId === section.id && section.laundryItems.map((item) => (
                  <div key={item.id} className={`flex justify-between ${styles.item}`}>
                    <div className={styles.itemData}>
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                      <p>{currency.currency} {item.price}</p>
                    </div>
                    <div onClick={(e) => e.stopPropagation()} className={styles.add}>
                      <div className={`flex ${styles.container}`}>
                        {!counts[item.id] ? (
                          <button className="flex items-center" onClick={() => increment(item.id)}>
                            <ReactplusIcon />
                            <span>Add</span> 
                          </button>
                        ): (
                          <div className={styles.counter}>
                            <button
                                className={styles.button}
                                onClick={() => decrement(item.id)}
                                disabled={counts[item.id] === 0}
                            >
                              {counts[item.id] === 1 ?(<ReacttrashIcon/>) : (<ReactColoredsubIcon/>)}
                            </button>
                            <span className={styles.count}>{counts[item.id]}</span>
                            <button disabled={counts[item.id] === item.maxOrderQuantity} className={styles.button} onClick={() => increment(item.id)}>
                              <ReactplusIcon/>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            </>
          )}
      </div>
    { !emptyCounts &&
      <div className={`${JSON.stringify(counts) === '{}' ? styles.inf: ""} flex justify-between ${styles.footer}`}>
              <div>
                <p>Total amount</p>
                <p>{currency.currency} {calculateTotal().toFixed(2)}</p>
              </div>
              <button onClick={onSubmit} className={`${styles.continue}`}>
                    Continue              
              </button>
      </div>
    }
    </div>
  );
};


export default LaundrySelectItem;
