// src/components/PickupTimes.tsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/selectTime.module.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchOperatingHours } from "../../redux/operatingHoursSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { toZonedTime } from 'date-fns-tz';
import Modal from "react-modal";
import { format, parse, addMinutes, isAfter, addDays, isSameDay, isEqual } from 'date-fns';
import { Day, DayWithDate, Shift, TimeZoneInfo } from "../../utils/types/times";
import { applyPickupMinsToToday, generateLaundrySlots, getNext7DaysWithDates } from "../../utils/times/operatingHours";

interface PickupTimeProps {
  onConfirm: (pickDate: string, pickTime: string) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean;
}

const PickupTimes: React.FC<PickupTimeProps> = ({ onConfirm, isOpen, onRequestClose, shouldAnimateOut }) => {
  const serviceCode: number = 2;
  const [dateSelected, setDateSelected] = useState<number>(0);
  const [timeSelected, setTimeSelected] = useState<string>('');
  const [processedDays, setProcessedDays] = useState<DayWithDate[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [timeZone, setTimeZone] = useState<TimeZoneInfo | null>(null);
  const [currentTimeInTZ, setCurrentTimeInTZ] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { operatingHours, error } = useSelector((state: RootState) => state.operatingHours);

  useEffect(() => {
    dispatch(fetchOperatingHours(serviceCode));
  }, [dispatch, serviceCode]);

  useEffect(() => {
    if (operatingHours && operatingHours.timeZone) {
      setTimeZone(operatingHours.timeZone);
      const zonedDate = toZonedTime(new Date(), operatingHours.timeZone.name);
      setCurrentTimeInTZ(zonedDate);
      setLoading(false);
    }
  }, [operatingHours]);

 
  useEffect(() => {
    if (
      operatingHours &&
      operatingHours.days.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const today = new Date(currentTimeInTZ);
      const next7Days = getNext7DaysWithDates(operatingHours.days, today);

      if (next7Days.length === 0) {
        setProcessedDays([]);
        return;
      }

      const todayDay = next7Days[0];
      const allSlotsToday = generateLaundrySlots(todayDay.shifts);
      const filteredSlotsToday = applyPickupMinsToToday(
        allSlotsToday,
        today,
        operatingHours.pickupMins,
        false
      );
      
      if (filteredSlotsToday.length === 0) {
        const reorderedDays = [...next7Days.slice(0)];
        
        setProcessedDays(reorderedDays);
      } else {
        setProcessedDays(next7Days);
      }
    }
  }, [operatingHours, currentTimeInTZ, timeZone]);


  const generateDates = () => {
    const dates: string[] = [];
    const today = currentTimeInTZ ? new Date(currentTimeInTZ) : new Date();

    processedDays.forEach((dayWithDate) => {
      const date = dayWithDate.date;
      const isTodayFlag = isSameDay(date, today);
      const formattedDate = format(date, 'MMMM d'); // e.g., "November 20"
      const dateString = isTodayFlag ? `Today, ${formattedDate}` : format(date, 'EEEE, MMMM d');
      dates.push(dateString);
    });

    return dates;
  };


  useEffect(() => {
    if (
      operatingHours &&
      processedDays.length > 0 &&
      currentTimeInTZ &&
      timeZone
    ) {
      const selectedDay = processedDays[dateSelected];
      if (selectedDay && selectedDay.shifts.length > 0) {
        let allSlots = generateLaundrySlots(selectedDay.shifts);

        const today = new Date(currentTimeInTZ);
        const isToday =
          format(selectedDay.date, "yyyy-MM-dd") ===
          format(today, "yyyy-MM-dd");

        if (isToday) {
          allSlots = applyPickupMinsToToday(
            allSlots,
            today,
            operatingHours.pickupMins,
            false
          );
        }

        setTimes(allSlots);
        setTimeSelected(allSlots[0] || "");
      } else {
        setTimes([]);
        setTimeSelected("");
      }
    }
  }, [dateSelected, operatingHours, currentTimeInTZ, processedDays, timeZone]);


  const handleConfirm = () => {
    if (processedDays[dateSelected] && timeSelected) {
      const selectedDay = processedDays[dateSelected];
      const selectedDate = selectedDay.date;
      const formattedDate = isSameDay(selectedDate, new Date())
        ? `Today, ${format(selectedDate, 'MMMM d')}`
        : format(selectedDate, 'EEEE, MMMM d');
      
        onConfirm(formattedDate, timeSelected);
        onRequestClose();
    }
  };

 
  const handleDateClick = (index: number) => {
    setDateSelected(index);
  };

  const handleTimeClick = (value: string) => {
    setTimeSelected(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.modalContent} ${shouldAnimateOut ? styles.exit : ''}`}
      overlayClassName={`${styles.modalOverlay} ${shouldAnimateOut ? styles.exitOverlay : ''}`}
    >
      <div className={`flex flex-col ${styles.container}`}>
        <h4>Select pick up date and time</h4>
        <div className={`flex ${styles.select} ${styles.modalselect}`}>
          <div className={`${styles.date}`}>
            {loading ? (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className={styles.skeletonLoading}>
                    {/* Placeholder for loading */}
                    <div className={styles.skeletonBox}></div>
                  </div>
                ))}
              </>
            ) : error ? (
              <div className={styles.error}>
                <p>Error: {error}</p>
                <button onClick={() => dispatch(fetchOperatingHours(serviceCode))}>Retry</button>
              </div>
            ) : processedDays.length === 0 ? (
              <p>No services available in the next 7 days.</p>
            ) : (
              <>
                {generateDates().map((date: string, index: number) => (
                  <div
                    key={index}
                    onClick={() => handleDateClick(index)}
                    className={`${styles.selectBox} ${dateSelected === index ? styles.selected : ''}`}
                  >
                    <p>{date.split(", ")[0]}</p>
                    <p>{date.split(", ")[1]}</p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={`${styles.time}`}>
            {loading ? (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className={styles.skeletonLoading}>
                    {/* Placeholder for loading */}
                    <div className={styles.skeletonBox}></div>
                  </div>
                ))}
              </>
            ) : times.length > 0 ? (
              times.map((time: string, index: number) => (
                <div
                  key={index}
                  onClick={() => handleTimeClick(time)}
                  className={`${styles.selectBox} ${timeSelected === time ? styles.selected : ''}`}
                >
                  <p>{time}</p>
                </div>
              ))
            ) : (
              <p>No available time slots for this day.</p>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <button onClick={handleConfirm} disabled={!timeSelected}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PickupTimes;

