import React, { useEffect, useState } from "react";
import styles from "../../styles/success.module.css";
import { useNavigate } from "react-router-dom";

interface SuccessProps {
  pickupData: { pickupDate: string; pickupTime: string };
  dropoffData: { dropOffDate: string; dropOffTime: string };
  items: any;
}

const Success: React.FC<SuccessProps> = ({
  items,
  pickupData,
  dropoffData,
}) => {
  const navigate = useNavigate();
  const [groupedData, setGroupedData] = useState<any>(null);

  useEffect(() => {
    if (items) setGroupedData(groupItemsBySection(items));
  }, [items]);

  const groupItemsBySection = (items: any) => {
    return items.reduce((acc: any, item: any) => {
      const sectionName = item.laundrySection.name;
      if (!acc[sectionName]) {
        acc[sectionName] = {
          items: [],
          totalAmount: 0,
        };
      }
      acc[sectionName].items.push(item);
      acc[sectionName].totalAmount += parseFloat(item.price) * item.count;
      return acc;
    }, {});
  };

  return (
    <>
      <span>
      <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.83325" y="3.33334" width="33.3333" height="33.3333" rx="16.6667" stroke="url(#paint0_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5691 20.5496L18.1808 24.1613L18.1574 24.1379L26.3058 15.9896" stroke="url(#paint1_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_5574_48097" x1="3.83325" y1="26.9445" x2="37.695" y2="24.0061" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5574_48097" x1="14.5691" y1="21.7779" x2="26.3979" y2="20.3036" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            </defs>
          </svg>

      </span>
      <div className={`flex flex-col ${styles.successData}`}>
        <h4 className="text-center">Laundry request confirmed</h4>
        <div className={` ${styles.details}`}>
          <div className={`flex ${styles.info}`}>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0001 8.99875L8.96898 9.75652C8.43296 9.89053 7.8898 9.56464 7.75579 9.02862L7.13933 6.56273C7.04704 6.19357 7.06193 5.80574 7.18227 5.44475L7.77042 3.68029C7.9066 3.27178 8.28889 2.99625 8.7195 2.99625H15.2807C15.7113 2.99625 16.0936 3.27179 16.2298 3.6803L16.9101 5.72115C16.9702 5.90165 16.9777 6.09557 16.9315 6.28015L16.2444 9.02865C16.1104 9.56467 15.5673 9.89056 15.0312 9.75655L12.0001 8.99875Z"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.0036 12V8.99875C21.0036 7.3412 19.6599 5.9975 18.0024 5.9975H16.9612"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.08036 5.9975H5.99759C4.34004 5.9975 2.99634 7.3412 2.99634 8.99875V18.0025C2.99634 19.66 4.34004 21.0037 5.99759 21.0037H13.0005"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.8716 3.19135L12 8.99876L8.12842 3.19135"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.05 15.0012C12.05 15.0289 12.0276 15.0513 12 15.0513C11.9723 15.0513 11.9499 15.0289 11.9499 15.0012C11.9499 14.9736 11.9723 14.9512 12 14.9512"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.0001 14.9512C12.0277 14.9512 12.0501 14.9736 12.0501 15.0013V15.0013"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.05 12C12.05 12.0276 12.0276 12.05 12 12.05C11.9723 12.05 11.9499 12.0276 11.9499 12C11.9499 11.9724 11.9723 11.95 12 11.95"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.0001 11.95C12.0277 11.95 12.0501 11.9724 12.0501 12V12"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.5027 21.504C18.5027 19.5702 16.935 18.0025 15.0012 18.0025C16.935 18.0025 18.5027 16.4348 18.5027 14.501C18.5027 16.4348 20.0703 18.0025 22.0041 18.0025C20.0703 18.0025 18.5027 19.5702 18.5027 21.504Z"
                  stroke="#8B98A4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>
              {groupedData &&
                Object.keys(groupedData).map((sectionName) => (
                  <div key={sectionName} className={` flex ${styles.section}`}>
                    <div>
                      <h2>{sectionName}</h2>
                      {groupedData[sectionName].items.map((item: any) => (
                        <div key={item.id} className={styles.item}>
                          <div className={`flex ${styles.itemData}`}>
                            <div>
                              <p>
                                x{item.count} {item.name}:{" "}
                                {sectionName !== "Dry Cleaning" &&
                                  item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.dateTime}>
            <div className={`flex ${styles.detailsItem}`}>
              <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.87988 3.74512V6.49512" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.1299 3.74512V6.49512" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2.75 9.70512H14.17" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.1299 9.9248V20.1348" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.1299 9.86511L22.3099 13.0451" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.13 9.86511L15.95 13.0451" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12512C2.75 6.46512 4.09 5.12512 5.75 5.12512H16.26C17.28 5.12512 18.18 5.63512 18.72 6.41512" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <div>
                <p>Pickup:</p>
                <p className="mt-1">{pickupData.pickupDate} , {pickupData.pickupTime}</p>
              </div>
            </div>
            <div className={`flex mt-2 ${styles.detailsItem}`}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.87988 3.74512V6.49512" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.1299 3.74512V6.49512" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2.75 9.70512H14.17" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M19.1299 20.0752V9.86523" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M19.1299 20.1348L22.3099 16.9548" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M19.1302 20.1348L15.9502 16.9548" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12511C2.75 6.46511 4.09 5.12511 5.75 5.12511H16.26C17.28 5.12511 18.18 5.63511 18.72 6.41511" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </span>
              <div>
                <p>Drop off:</p>
                <p className="mt-1">{dropoffData.dropOffDate} , {dropoffData.dropOffTime}</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <button
        onClick={() => navigate("/app")}
        type="submit"
        className={styles.done}
      >
        Done
      </button>
    </>
  );
};

export default Success;
