// src/redux/servicesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Service } from '../utils/types/services';

// Define the state interface
interface ServicesState {
  services: Service[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ServicesState = {
  services: [],
  loading: false,
  error: null,
};

// Async thunk for fetching services
export const fetchServices = createAsyncThunk<
  Service[],
  void,
  { rejectValue: string }
>('services/fetchServices', async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('PropertyToken');
    if (!token) {
      return rejectWithValue('PropertyToken not found');
    }

    const response = await axios.get<Service[]>(
      `${process.env.REACT_APP_BACKEND_URL}services`,
      {
        headers: {
          propertyToken: token,
        },
      }
    );

    // Assuming the API returns the services directly as an array
    return response.data;
  } catch (error: any) {
    // Handle errors appropriately
    if (axios.isAxiosError(error) && error.response) {
      return rejectWithValue(error.response.data.message || 'Failed to fetch services');
    }
    return rejectWithValue('An unexpected error occurred');
  }
});

// Create the slice
const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // You can add synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServices.fulfilled, (state, action: PayloadAction<Service[]>) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch services';
      });
  },
});

export default servicesSlice.reducer;
