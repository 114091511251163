import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import styles from "../../styles/foodService/payment.module.css";
import PaymentDropdown from "../PaymentDropdown";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PhoneModal from "../phoneModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import GroceryPaymentCard from "./stripePayment";
import notify from "../Errortoast";

interface PaymentProps {
  onBack: () => void
  allowSubstitution: boolean
}

const GroceryPayment: React.FC<PaymentProps> = ({
  onBack,
  allowSubstitution
}) => {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [checkoutData, setCheckoutData] = useState<any>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const phoneNumber: string | null = localStorage.getItem("phoneNumber");
  const parsedPhoneNumber = phoneNumber ? JSON.parse(phoneNumber) : null;  const [loading, setLoading] = useState<boolean>(true);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const orderItems:any  = localStorage.getItem("groceryItems")
  const parsedOrderItems = JSON.parse(orderItems || [])
  

  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        const groceryItems = localStorage.getItem("groceryItems");
        if (!token) {
          throw new Error("PropertyToken not found");
        }
        if (!groceryItems || JSON.stringify(groceryItems) === "{}") return navigate(0);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}services/groceries/checkout`,
          {
            items: JSON.parse(groceryItems),
          },
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setCheckoutData(response.data);
      } catch (error: any) {
        navigate("/app/groceries/categories")
        notify({ type: 'error', content: error.response?.data?.error || 'Failed to checkout' });
      }finally{
        setLoading(false)
      }
    };

    fetchServices();
  }, []);

  const handlePaymentMethodChange = (method: string, label: string) => {
    setSelectedMethod(method);
    setSelectedLabel(label);
  };

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div
          className={styles.backArrow}
          onClick={() => onBack()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
      <div className={styles.checkOut}>
        <h1>Checkout</h1>
        <div className={styles.details}>
          <h4>Details</h4>
          <div className={`${styles.serviceDetail}`}>
            <div className={`flex ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.25 19.85C12.25 19.85 5.95001 14.675 5.95001 9.94999C5.95001 6.47059 8.77061 3.64999 12.25 3.64999C15.7294 3.64999 18.55 6.47059 18.55 9.94999C18.55 14.675 12.25 19.85 12.25 19.85Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2501 12.4251V12.4251C10.8831 12.4251 9.77509 11.3171 9.77509 9.9501V9.9501C9.77509 8.58307 10.8831 7.4751 12.2501 7.4751V7.4751C13.6171 7.4751 14.7251 8.58307 14.7251 9.9501V9.9501C14.7251 11.3171 13.6171 12.4251 12.2501 12.4251Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  `${checkoutData.departmentNumber}, ${checkoutData.buildingName}. , ${checkoutData.Area.name.toLowerCase()}`
                )}
              </p>
            </div>
            <div className={`flex items-center ${styles.detailsItem}`}>
              <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.2619 20.7738C16.9629 20.7738 20.7738 16.9629 20.7738 12.2619C20.7738 7.56091 16.9629 3.75 12.2619 3.75C7.56091 3.75 3.75 7.56091 3.75 12.2619C3.75 16.9629 7.56091 20.7738 12.2619 20.7738Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.2618 8.98816V12.262L15.588 16.1382" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              <p>Delivery in 30 - 45 min</p>
            </div>
            {parsedPhoneNumber &&
              <div onClick={openModal} className={`flex items-center justify-between ${styles.detailsItem}`}> 
                <div className={`flex items-center`}>
                  <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10 18H14" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <p className="ml-2">{parsedPhoneNumber.number}</p>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16L15 12L11 8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            }
          </div>
        </div>
        <div className={styles.paymentSummary}>
          <h4>Payment summary</h4>
          <div className={` ${styles.serviceDetail}`}>
            <div className={styles.paymentMethod}>
              <PaymentDropdown
                selectedLabel={selectedLabel}
                selectedMethod={selectedMethod}
                onChange={handlePaymentMethodChange}
              />
            </div>
            <hr />
            <div className={styles.amounts}>
            {parsedOrderItems &&
                Object.values(parsedOrderItems).map((item: any) => (
                  <div
                    key={item.id}
                    className={` flex justify-between ${styles.subTotal}`}
                  >
                    <p className="w-3/4">
                    x{item.quantity} {item.name}
                    </p>
                    {loading ? (
                      <Skeleton
                        height={20}
                        width={100}
                        baseColor="#F2F5F8"
                        highlightColor="#fff"
                      />
                    ) : (
                      <p>AED {(item.grossPrice * item.quantity).toFixed(2)}</p>
                    )}
                  </div>
                ))}
              <div className={` flex justify-between ${styles.subTotal} ${styles.discountAmount}`}>
                    
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <>
                  {checkoutData.discountedAmount > 0 &&
                  <>
                    <p>
                      Discount 30%
                    </p>
                    <p>AED {checkoutData.discountedAmount}</p>
                  </>

                  } 
                  </>
                )}
              </div>
              <hr />
              <div className={` flex justify-between ${styles.subTotal}`}>
                <p>Subtotal</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.subtotal}</p>
                )}
              </div>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                <p>Delivery fee</p>
                  {loading ? (
                    <Skeleton
                      height={20}
                      width={100}
                      baseColor="#F2F5F8"
                      highlightColor="#fff"
                    />
                  ) : (
                    <p>AED {checkoutData.deliveryFee.toFixed(2)}</p>
                  )}
                </div>
              <hr/>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                <p>Service fee</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.serviceFee}</p>
                )}
              </div>
              <hr />
              <div className={` flex justify-between ${styles.total}`}>
                <p>Total amount</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.total}</p>
                )}
              </div>
              <div className={styles.extaData}>

              </div>
            </div>
          </div>
        </div>
    </div>
    { checkoutData &&
                <div className={styles.footer}>
                    { (parsedPhoneNumber && checkoutData && checkoutData) ? (
                            <Elements stripe={stripePromise}>
                                <GroceryPaymentCard items={parsedOrderItems} allowSubstitution={allowSubstitution} phoneNumber={parsedPhoneNumber} checkoutData={checkoutData} paymentMethod={selectedMethod} />
                            </Elements>
                        ) : (
                            <button onClick={openModal}>Continue</button>  
                        )
                    }
                </div>
    }
      <PhoneModal shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default GroceryPayment;
