import React, { useEffect, useState } from "react";
import styles from "../../styles/groceryService/selectCategory.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GroceryCategory } from "../../utils/types/services";
import SearchItems from "./searchItems";
import { centeredImgOnetext } from "../../utils/skeleton";



const SelectCategory: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [isValidSearch, setIsValidSearch] = useState(true);
  const [newEntry, setNewEntry] = useState<boolean>(false)
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderItems, setOrderItems] = useState<{
    [key: number]: { quantity: number; data: any };
  }>({});



  useEffect(() => {
    const groceries = localStorage.getItem("groceryItems");
    if (groceries) {
      const parsedOrders = JSON.parse(groceries);
      setOrderItems(parsedOrders);
    }
  }, [newEntry]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/groceries/categories`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setLoading(false);
        setCategories(response.data.data);
      } catch (error) {
        setCategories([]);
        navigate("/app")

      } finally {
      }
    };

    fetchServices();
  }, []);


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const trimmedValue = value.trim();

    if (trimmedValue.length < 2 || trimmedValue.length > 50) {
      setIsValidSearch(false);
    } else {
      setIsValidSearch(true);
    }

    setSearchValue(value);
  };

  return (
    <div className={styles.grocerySelectCategory}>
      <header
        className={`${styles.header}`}
      >
        <div
          className={`flex ${styles.upper} place-content-between items-center `}
        >
          <span
            onClick={() => {
              navigate("/app");
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6L8 12.1222L14 18.2444"
                stroke="#323E48"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </svg>
          </span>
        </div>
        <div className={styles.title}>
          <h1>Groceries</h1>
          <p>Approx delivery 30 - 45 min</p>
        </div>
        <div></div>
      </header>
      <div className={styles.data}>
        <div className={styles.search}>
          <input
            type="text"
            maxLength={50}
            placeholder="What are you looking for?"
            value={searchValue}
            onChange={handleSearch}
          />
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.2395"
                cy="11.2396"
                r="5.64955"
                stroke="#8B98A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3953 18.3952L15.234 15.2339"
                stroke="#8B98A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
        {searchValue !== "" && isValidSearch ? (
          <SearchItems onChange={()=> setNewEntry(!newEntry)} isValidSearch={isValidSearch} searchQuery={searchValue} />
        ) : (
          <>
          {loading ? (
            <div className={styles.categories}>
              {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <div key={index}>
                  {centeredImgOnetext()}
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.categories}>
            {categories &&
              categories.map((category: GroceryCategory) => (
                <div
                  onClick={() => {
                    navigate(`${category.id}`);
                  }}
                  key={category.id}
                  className={styles.category}
                >
                  <div className={styles.imageContainer}>
                    <img
                      src={category.image || "/services/groceries.png"}
                      alt={category.name}
                    />
                  </div>
                  <p>{category.name}</p>
                </div>
              ))}
          </div>
          )}
        </>
        )}
      </div>
      {Object.keys(orderItems).length > 0 && (
        <div className={styles.footer}>
          <button
            onClick={()=> navigate('/app/groceries/checkout')}
            className={`flex items-center justify-between ${styles.continue}`}
          >
            <div className="flex items-center gap-2">
              <span>
                {Object.values(orderItems).reduce(
                  (sum: number, item: any) => sum + item.quantity,
                  0
                )}
              </span>
              <p>Go to basket</p>
            </div>
            <p>
              AED{" "}
              {Object.values(orderItems)
                .reduce(
                  (sum: number, item: any) =>
                    sum + item.quantity * item.grossPrice,
                  0
                )
                .toFixed(2)}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectCategory;
