import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import SelectTime from "../../components/cleaningService/selectTime";
import Payment from "../../components/cleaningService/payment";
import "../../styles/switchTransition.css";
import {Helmet} from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface TimeData {
  dateSelected: string;
  timeSelected: string;
}

const Checkout: React.FC = () => {
  const [currentComponent, setCurrentComponent] = useState<'selectTime' | 'payment'>('selectTime');
  const [selectedTimeData, setSelectedTimeData] = useState<TimeData | null>(null);
  const [isForward, setIsForward] = useState<boolean>(true);
  const [themeColor, setThemeColor] = useState('#ffffff'); 
  const navigate = useNavigate()

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user-services/${1}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        if (!response.data.isAvailable)
          navigate("/app")
      } catch (error) {
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-header-color');
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const handleContinue = (data: TimeData) => {
    setSelectedTimeData(data);
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent('payment');
    }, 100);
  };

  const handleBack = () => {
    setIsForward(false);
    setTimeout(() => {
      setCurrentComponent('selectTime');
    }, 100);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentComponent}
        timeout={200}
        classNames={isForward ? "fade-forward" : "fade-backward"}
      >
        <div className="transition-wrapper">
          {currentComponent === 'selectTime' && (
            <>
              <Helmet>
                <meta name="theme-color" content={themeColor} />
              </Helmet>
              <SelectTime onContinue={handleContinue} />
            </>
          )}
          {currentComponent === 'payment' && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff" />
              </Helmet>
              <Payment onBack={handleBack} timeData={selectedTimeData} />
            </>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Checkout;
