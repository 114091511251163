import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/newOrders.module.css";
import axios from "axios";
import { Order, SelectedOrder } from "../utils/types/services";
import {
  convertDateBackOnly,
  convertDateTimeBack,
  formatCreatedDate,
} from "../utils/times/timeDateConvert";
import RatingModal from "../pages/orders/ratingModal";

const NewOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const initialSelectedOrder: SelectedOrder = {
    orderId: null,
    rating: null,
    service: null,
  };
  const [selectedOrder, setSelectedOrder] =
    useState<SelectedOrder>(initialSelectedOrder);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
    setSelectedOrder(initialSelectedOrder);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300); // Match the duration of the slide-out animation
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);

  function toCamelCase(str: string): string {
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/orders`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );

        setOrders(response.data);
      } catch (error) {
        // Handle the error here, e.g., show an error message
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    orders.map((order: Order) => {
      if (!order.isOrderRated && order.status === "Completed") {
        setSelectedOrder({
          orderId: order.id,
          rating: null,
          service: order.service,
        });
        openModal();
      }
    });
  }, [orders]);
  const orderHistory = (order: Order) => {
    if (!order) return;

    switch (order.service.code) {
      case 1:
        return (
          <p>
            {convertDateTimeBack(order.date, order.time).dateString},{" "}
            {convertDateTimeBack(order.date, order.time).timeStr}
          </p>
        );
      case 2:
        return (
          <p>
            Drop off: {convertDateBackOnly(order.dropoffDate)},{" "}
            {order.dropoffTime}
          </p>
        );
      case 3:
      case 5:
        return <p>Delivery: in 30 - 45 min</p>;
      case 4:
        return <p>{formatCreatedDate(order.createdAt)}</p>;
    }
  };

  const handleOrderClick = (order: Order) => {
    switch (order.service.code) {
      case 1:
        navigate(`/app/orders/housekeeping/${order.service.code}/${order.id}`)
        break;
      case 2:
        navigate(`/app/orders/laundry/${order.service.code}/${order.id}`) 
        break;
      case 3:
        navigate(`/app/orders/food/${order.service.code}/${order.id}`)
        break;
      case 4:
        navigate(`/app/orders/freshTowels/${order.service.code}/${order.id}`)
        break;
      case 5:
        navigate(`/app/orders/groceries/${order.service.code}/${order.id}`)
        break;
      default:
        break;
    }
  };

  return (
    <>
      {orders.length > 0 && (
        <div className={styles.recentOrders}>
          {orders
            .filter((order) => order.status === "In progress")
            .map((order, index) => (
              <div
                onClick={() => handleOrderClick(order)}
                className={`flex ${styles.orderItem}`}
                key={index}
              >
                <div className={styles.imageContainer}>
                  <div className={styles.card}>
                    <div className={styles.percent}>
                      <svg>
                        <circle cx="105" cy="105" r="100"></circle>
                        <circle
                          cx="26.25"
                          cy="26.25"
                          r="25"
                          style={
                            order &&
                            ({ "--percent": "-75" } as React.CSSProperties)
                          }
                        ></circle>
                      </svg>
                      <div className={styles.number}>
                        <img
                          src={`/services/recentOrders/${toCamelCase(
                            order.service.code.toString()
                          )}.svg`}
                          alt={order.service.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.data}>
                  <p>
                    {order.service.name.charAt(0).toUpperCase() +
                      order.service.name.slice(1)}
                  </p>
                  {orderHistory(order)}
                </div>
              </div>
            ))}
        </div>
      )}
      {selectedOrder.orderId && (
        <RatingModal
          selectedOrder={selectedOrder}
          shouldAnimateOut={shouldAnimateOut}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default NewOrders;
